// Importing necessary dependencies from Redux
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger'

// Importing the root reducer
import rootReducer from './Reducers';

// Configuring redux-persist
const persistConfig = {
  key: 'root',
  storage
  // Optionally, you can whitelist specific reducers to persist
  // whitelist: ['reducer1', 'reducer2']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Creating a store function that returns store and persist objects
const configureStore = () => {
  const store = createStore(
    persistedReducer,
    undefined, // preloaded state
    compose(
      // applyMiddleware(thunk, logger), // Adding middlewares to the store - removed 28/9/24 JW
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && // Adding support for Redux DevTools extension
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
        compose
    )
  );

  const persistor = persistStore(store);

  // Returning store and persist objects
  return { store, persistor };
};

export default configureStore();
