import React, { useRef } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

export default function Customcamera(props) {
  const inputFile = useRef(null);

  // file upload
  const handleFileUpload = (event) => {
    props.handleFileChange(event);
    props.setDisplayPhotoField(true);
  };

  // open mobile camera
  const openMobileCamera = (event) => {
    event.preventDefault();
    inputFile.current.click();
  };

  return (
    // camera for mobile
    <div>
      <input
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
        accept={
          props.type === 'file'
            ? '.pdf, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx'
            : 'image/*'
        }
      />
      <div className="photo-camera-button" onClick={openMobileCamera}>
        <AddAPhotoIcon sx={{ color: 'var(--orange-color)' }} />
        <label className="text-small black-font">Open camera</label>
      </div>
    </div>
  );
}
