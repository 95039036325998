import React, { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './TextField.styles.css';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

export default function CustomTextField(props) {
  const {
    id,
    fullWidth,
    onChange,
    onBlur,
    error,
    helperText,
    resetValue,
    autocomplete,
    multiline, // Add a multiline prop to determine the input type
    rows, // Add a rows prop for multiline text areas
    type,
    form_disabled,
    onInputChange,
    formikProps,
    inputStyles,
    textColor,
    textSize,
    ...rest
  } = props;
  const [value, setValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleOnChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };
  const handleOnBlur = (event) => {
    if (onBlur) {
      onBlur && onBlur(event);
    }
  };
  const handleClearText = () => {
    setValue('');
    if (onChange) {
      onChange({
        target: {
          id,
          value: ''
        }
      });
      onInputChange && onInputChange(id, type === 'text' || type === 'password' ? '' : null);
    }
  };

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);
  return (
    <div className="text-field-container">
      <div className="fieldSet">
        {multiline ? (
          <textarea
            id={id}
            value={value}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            autoComplete={autocomplete === false ? 'on' : 'off'}
            disabled={form_disabled}
            className={'text-small input-field'}
            style={{
              border: error ? '1px solid var(--orange-color)' : `1px solid ${inputStyles}`
            }}
            rows={rows} // Set the number of rows for multiline text areas
            {...rest}
          />
        ) : (
          <input
            id={id}
            value={value}
            onBlur={handleOnBlur}
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
            disabled={form_disabled}
            onChange={handleOnChange}
            onWheel={(e) => e.target.blur()}
            autoComplete={autocomplete === false ? 'on' : 'off'}
            className={`${textColor ? textColor : 'emerald-font'} ${textSize ? textSize : 'text-standard'} input-field`}
            style={{
              border: error ? '1px solid var(--orange-color)' : `1px solid ${inputStyles}`,
              color: textColor ? textColor : 'black-font'
            }}
            {...rest}
          />
        )}
        <div className="clearText">
          {type === 'password' ? (
            <>
              {showPassword ? (
                <VisibilityOffIcon
                  sx={{
                    visibility: value ? 'visible' : 'hidden',
                    color: inputStyles,
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityIcon
                  sx={{
                    visibility: value ? 'visible' : 'hidden',
                    color: inputStyles,
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </>
          ) : (
            <CancelIcon
              sx={{
                visibility: value ? 'visible' : 'hidden',
                color: error ? 'var(--orange-color)' : inputStyles,
                cursor: 'pointer'
              }}
              onClick={handleClearText}
            />
          )}
        </div>
      </div>
      <checkbox label={'showpassword'} />
      <label className="text-small orange-font"> {helperText} </label>
    </div>
  );
}

CustomTextField.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  form_disabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  textColor: PropTypes.string
};
