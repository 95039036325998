export const SET_RESIDENT_ID = 'SET_RESIDENT_ID';

export const TOGGLE_DEMO_MODE = 'TOGGLE_DEMO_MODE';

export const SET_INSTALLER_DETAILS = 'SET_INSTALLER_DETAILS';

export const SET_LEADS_DETAILS = 'SET_LEADS_DETAILS';

export const SET_ORDERS_DETAILS = 'SET_ORDERS_DETAILS';
export const SET_JOURNEY_ID = 'SET_JOURNEY_ID';
export const SET_RESIDENT_DETAILS = 'SET_RESIDENT_DETAILS';
export const SET_JOURNEY_DETAILS = 'SET_JOURNEY_DETAILS';

export const SET_LOADING = 'SET_LOADING';

export const SET_DETAIL_DATA_SUBMISSION_DETAIL = 'SET_DETAIL_DATA_SUBMISSION_DETAIL';

export const SET_HOME_STATS = 'SET_HOME_STATS';

export const UPDATE_LEAD = 'UPDATE_LEAD';

export const UPDATE_ORDER = 'UPDATE_ORDER';

export const UPDATE_INSTALLER_DETAILS = 'UPDATE_INSTALLER_DETAILS';

export const LOGOUT = 'LOGOUT';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
