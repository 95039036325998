import { Grid } from '@mui/material';
import warningIcon from '../../../images/alert-triangle.svg';
import errorIcon from '../../../images/alert-circle.svg';
import './Alert.style.css';
export default function Alert(props) {
  const { type, message } = props;

  const bgColor = {
    warning: 'var(--warning-color)',
    error: 'var(--error-color)',
    success: 'var(--success-color)'
  };
  return (
    <div className={'alert-container'} style={{ background: bgColor[type] }}>
      <div>
        <img src={type === 'error' ? errorIcon : warningIcon} className={'alert-icon-style'} />
      </div>
      <div className={'text-container'}>
        <p className={'text-standard black-font'}>{message}</p>
      </div>
    </div>
  );
}
