import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { post } from '../services/API';

export const CallbackPage = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
  const navigate = useNavigate();
  const getFulllReport = async () => {
    if (isAuthenticated) {
      const temUser = {
        resident_id: user.sub,
        first_name: user.given_name,
        last_name: user.family_name,
        email: user.email,
        contact_number: null
      };
      const result = await post('users', `createResident`, temUser);
    }
    // navigate('/resident-home')
  };
  useEffect(() => {}, []);
  return <></>;
};
