export const installerLeadsDemoData = [
  {
    lead_id: 5,
    resident_id: 100780,
    resident_first_name: 'Alice',
    resident_last_name: 'Smith',
    property_address: '789 Oak Street',
    property_town: 'London',
    property_postcode: 'SW1A 1AA',
    journey_id: 100780,
    lead_status: 1,
    property_coords: {
      lat: 51.282006,
      lon: -2.766804,
      region: 'South West'
    },
    journey: {
      journey_id: 100780,
      resident_id: 100780,
      property_id: '10',
      selected_installer_id: 2,
      selected_quotation_id: 1,
      post_survey_form_submission_id: null,
      post_installation_form_submission_id: null,
      journey_date_created: '2024-03-15T00:00:00.000Z',
      assessment_complete: 2,
      property_details_complete: 0,
      energy_consumption_complete: 0,
      shape_of_my_home_complete: 0,
      photos_of_my_home_complete: 2,
      price_estimates_requested: true,
      photos_accepted: false,
      survey_dates_requested: false,
      installers_provided: false,
      installer_selected: true,
      survey_dates_provided: false,
      survey_dates_selected: false,
      survey_booked: false,
      survey_complete: false,
      survey_documents_received: false,
      contract_signed: false,
      dno_notified: false,
      dno_confirmation: false,
      cool_down_start_date: null,
      cool_down_end_date: null,
      cool_down_completed: false,
      deposit_paid: false,
      installation_requested: false,
      equipment_ordered: false,
      installation_dates_provided: false,
      installation_dates_selected: false,
      installation_booked: false,
      installation_started: false,
      installation_completed: false,
      confirmation_documents_signed: false,
      outstanding_balance_paid: false,
      final_documentation_received: false,
      form_submissions: [
        {
          form_submission_id: '105060',
          form_description: 'heat_pump_readiness'
        },
        {
          form_submission_id: '105080',
          form_description: 'heat_pump_assumptions'
        }
      ],
      type: 'survey',
      thermly_heatloss_summary: {
        score_description: [
          {
            colour: '#92D050',
            description:
              'Having insulated walls is a big first step in being heat pump ready. All looks good at your place! Toasty.'
          },
          {
            colour: '#92D050',
            description:
              'An insulated roof. That should mean not much heat being lost up top which is ideal for heat pump installation.'
          },
          {
            colour: '#FFD966',
            description:
              "We'd recommend getting single glazing either replaced with double or triple glazing, or if that's not possible, to consider secondary glazing instead. More heat is lost through windows by area than anywhere else - so getting this sorted is important before progressing to installing a heat pump."
          }
        ],
        quote_breakdown: {
          heat_pump_size: '5',
          quote_breakdown: [
            {
              metrics_name: 'Equipment',
              metrics_value: '4700'
            },
            {
              metrics_name: 'Installation',
              metrics_value: '4000'
            },
            {
              metrics_name: 'Possible extras',
              metrics_value: '2600'
            },
            {
              metrics_name: 'Government grant',
              metrics_value: '-7500'
            },
            {
              metrics_name: 'Final cost',
              metrics_value: '3800'
            }
          ]
        }
      },
      resident_address: {
        property_id: '10',
        uprn: null,
        address_line_1: '2 kelsall place',
        address_line_2: '',
        address_line_3: '',
        post_town: 'Leeds',
        postcode: 'LS61RA',
        postcode_spaceless: 'LS61RA'
      },
      selected_installer: [
        {
          installer_id: 2,
          contact_name: 'Jhon Doe',
          contact_number: null,
          email: 'installer@yahoo.com',
          password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
          is_verified: true,
          social_signin: false,
          website_link: null,
          property_id: 11,
          date_created: '2024-03-14T00:00:00.000Z',
          installer_verification: true,
          logo: null,
          terms_and_conditions: true,
          company_name: 'Ecomatics',
          mcs_number: null,
          certification_body: null,
          company_bio: null,
          documents_form_submission_id: null,
          address: {
            property_id: '36',
            uprn: '77122047',
            address_line_1: '17 Piccadilly',
            address_line_2: '',
            address_line_3: '',
            post_town: 'Manchester',
            postcode: 'M1 1LS',
            postcode_spaceless: 'M11LS'
          },
          quotation: [
            {
              quotation_id: 1,
              journey_id: 100780,
              quotation_type: null,
              installer_id: 2,
              date_created: '2024-03-15T00:00:00.000Z',
              stage_type: 'pre_survey',
              gross_price: 14998,
              net_price: 7498,
              deposit_value: null,
              balance_value: null,
              manufacturer: 'Bosch',
              heatpump_size: '5',
              info_link: 'example.com',
              lead_id: 1,
              model: '2.5',
              goods: null,
              services: null,
              grants: [
                {
                  component_name: 'Boiler upgrade scheme',
                  component_value: -7500
                }
              ],
              estimate: [
                {
                  component_name: 'Equipment',
                  component_value: 12000
                },
                {
                  component_name: 'Installation',
                  component_value: 2000
                },
                {
                  component_name: 'Possible extras',
                  component_value: 998
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    lead_id: 4,
    resident_id: 100780,
    resident_first_name: 'Frank',
    resident_last_name: 'Johnson',
    property_address: '123 Cedar Street',
    property_town: 'Edinburgh',
    property_postcode: 'EH1 1CD',
    journey_id: 100780,
    lead_status: 2,
    property_coords: {
      lat: 51.282006,
      lon: -2.766804,
      region: 'South West'
    },
    journey: {
      journey_id: 100780,
      resident_id: 100780,
      property_id: '10',
      selected_installer_id: 2,
      selected_quotation_id: 1,
      post_survey_form_submission_id: null,
      post_installation_form_submission_id: null,
      journey_date_created: '2024-03-15T00:00:00.000Z',
      assessment_complete: 2,
      property_details_complete: 0,
      energy_consumption_complete: 0,
      shape_of_my_home_complete: 0,
      photos_of_my_home_complete: 2,
      price_estimates_requested: true,
      photos_accepted: false,
      survey_dates_requested: false,
      installers_provided: false,
      installer_selected: true,
      survey_dates_provided: true,
      survey_dates_selected: true,
      survey_booked: true,
      survey_complete: true,
      survey_documents_received: true,
      contract_signed: true,
      dno_notified: false,
      dno_confirmation: false,
      cool_down_start_date: null,
      cool_down_end_date: null,
      cool_down_completed: false,
      deposit_paid: false,
      installation_requested: false,
      equipment_ordered: false,
      installation_dates_provided: false,
      installation_dates_selected: false,
      installation_booked: false,
      installation_started: false,
      installation_completed: false,
      confirmation_documents_signed: false,
      outstanding_balance_paid: false,
      final_documentation_received: false,
      form_submissions: [
        {
          form_submission_id: '105060',
          form_description: 'heat_pump_readiness'
        },
        {
          form_submission_id: '105080',
          form_description: 'heat_pump_assumptions'
        },
        {
          form_submission_id: '105260',
          form_description: 'photos_of_my_home'
        }
      ],
      type: 'survey',
      thermly_heatloss_summary: {
        score_description: [
          {
            colour: '#92D050',
            description:
              'Having insulated walls is a big first step in being heat pump ready. All looks good at your place! Toasty.'
          },
          {
            colour: '#92D050',
            description:
              'An insulated roof. That should mean not much heat being lost up top which is ideal for heat pump installation.'
          },
          {
            colour: '#FFD966',
            description:
              "We'd recommend getting single glazing either replaced with double or triple glazing, or if that's not possible, to consider secondary glazing instead. More heat is lost through windows by area than anywhere else - so getting this sorted is important before progressing to installing a heat pump."
          }
        ],
        quote_breakdown: {
          heat_pump_size: '5',
          quote_breakdown: [
            {
              metrics_name: 'Equipment',
              metrics_value: '4700'
            },
            {
              metrics_name: 'Installation',
              metrics_value: '4000'
            },
            {
              metrics_name: 'Possible extras',
              metrics_value: '2600'
            },
            {
              metrics_name: 'Government grant',
              metrics_value: '-7500'
            },
            {
              metrics_name: 'Final cost',
              metrics_value: '3800'
            }
          ]
        }
      },
      resident_address: {
        property_id: '10',
        uprn: null,
        address_line_1: '2 kelsall place',
        address_line_2: '',
        address_line_3: '',
        post_town: 'Leeds',
        postcode: 'LS61RA',
        postcode_spaceless: 'LS61RA'
      },
      selected_installer: [
        {
          installer_id: 2,
          contact_name: 'Jhon Doe',
          contact_number: null,
          email: 'installer@yahoo.com',
          password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
          is_verified: true,
          social_signin: false,
          website_link: null,
          property_id: 11,
          date_created: '2024-03-14T00:00:00.000Z',
          installer_verification: true,
          logo: null,
          terms_and_conditions: true,
          company_name: 'Ecomatics',
          mcs_number: null,
          certification_body: null,
          company_bio: null,
          documents_form_submission_id: null,
          address: {
            property_id: '36',
            uprn: '77122047',
            address_line_1: '17 Piccadilly',
            address_line_2: '',
            address_line_3: '',
            post_town: 'Manchester',
            postcode: 'M1 1LS',
            postcode_spaceless: 'M11LS'
          },
          quotation: [
            {
              quotation_id: 1,
              journey_id: 100780,
              quotation_type: null,
              installer_id: 2,
              date_created: '2024-03-15T00:00:00.000Z',
              stage_type: 'pre_survey',
              gross_price: 14998,
              net_price: 7498,
              deposit_value: null,
              balance_value: null,
              manufacturer: 'Bosch',
              heatpump_size: '5',
              info_link: 'example.com',
              lead_id: 1,
              model: '2.5',
              goods: null,
              services: null,
              grants: [
                {
                  component_name: 'Boiler upgrade scheme',
                  component_value: -7500
                }
              ],
              estimate: [
                {
                  component_name: 'Equipment',
                  component_value: 12000
                },
                {
                  component_name: 'Installation',
                  component_value: 2000
                },
                {
                  component_name: 'Possible extras',
                  component_value: 998
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    lead_id: 3,
    resident_id: 100780,
    resident_first_name: 'Charlie',
    resident_last_name: 'Brown',
    property_address: '123 Maple Street',
    property_town: 'Birmingham',
    property_postcode: 'B1 1AB',
    journey_id: 100780,
    lead_status: 0,
    property_coords: {
      lat: 51.282006,
      lon: -2.766804,
      region: 'South West'
    },
    journey: {
      journey_id: 100780,
      resident_id: 100780,
      property_id: '10',
      selected_installer_id: 2,
      selected_quotation_id: 1,
      post_survey_form_submission_id: null,
      post_installation_form_submission_id: null,
      journey_date_created: '2024-03-15T00:00:00.000Z',
      assessment_complete: 2,
      property_details_complete: 0,
      energy_consumption_complete: 0,
      shape_of_my_home_complete: 0,
      photos_of_my_home_complete: 2,
      price_estimates_requested: true,
      photos_accepted: false,
      survey_dates_requested: false,
      installers_provided: false,
      installer_selected: true,
      survey_dates_provided: false,
      survey_dates_selected: false,
      survey_booked: false,
      survey_complete: false,
      survey_documents_received: false,
      contract_signed: false,
      dno_notified: false,
      dno_confirmation: false,
      cool_down_start_date: null,
      cool_down_end_date: null,
      cool_down_completed: false,
      deposit_paid: false,
      installation_requested: false,
      equipment_ordered: false,
      installation_dates_provided: false,
      installation_dates_selected: false,
      installation_booked: false,
      installation_started: false,
      installation_completed: false,
      confirmation_documents_signed: false,
      outstanding_balance_paid: false,
      final_documentation_received: false,
      form_submissions: [
        {
          form_submission_id: '105060',
          form_description: 'heat_pump_readiness'
        },
        {
          form_submission_id: '105080',
          form_description: 'heat_pump_assumptions'
        },
        {
          form_submission_id: '105260',
          form_description: 'photos_of_my_home'
        }
      ],
      type: 'survey',
      thermly_heatloss_summary: {
        score_description: [
          {
            colour: '#92D050',
            description:
              'Having insulated walls is a big first step in being heat pump ready. All looks good at your place! Toasty.'
          },
          {
            colour: '#92D050',
            description:
              'An insulated roof. That should mean not much heat being lost up top which is ideal for heat pump installation.'
          },
          {
            colour: '#FFD966',
            description:
              "We'd recommend getting single glazing either replaced with double or triple glazing, or if that's not possible, to consider secondary glazing instead. More heat is lost through windows by area than anywhere else - so getting this sorted is important before progressing to installing a heat pump."
          }
        ],
        quote_breakdown: {
          heat_pump_size: '5',
          quote_breakdown: [
            {
              metrics_name: 'Equipment',
              metrics_value: '4700'
            },
            {
              metrics_name: 'Installation',
              metrics_value: '4000'
            },
            {
              metrics_name: 'Possible extras',
              metrics_value: '2600'
            },
            {
              metrics_name: 'Government grant',
              metrics_value: '-7500'
            },
            {
              metrics_name: 'Final cost',
              metrics_value: '3800'
            }
          ]
        }
      },
      resident_address: {
        property_id: '10',
        uprn: null,
        address_line_1: '2 kelsall place',
        address_line_2: '',
        address_line_3: '',
        post_town: 'Leeds',
        postcode: 'LS61RA',
        postcode_spaceless: 'LS61RA'
      },
      selected_installer: [
        {
          installer_id: 2,
          contact_name: 'Jhon Doe',
          contact_number: null,
          email: 'installer@yahoo.com',
          password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
          is_verified: true,
          social_signin: false,
          website_link: null,
          property_id: 11,
          date_created: '2024-03-14T00:00:00.000Z',
          installer_verification: true,
          logo: null,
          terms_and_conditions: true,
          company_name: 'Ecomatics',
          mcs_number: null,
          certification_body: null,
          company_bio: null,
          documents_form_submission_id: null,
          address: {
            property_id: '36',
            uprn: '77122047',
            address_line_1: '17 Piccadilly',
            address_line_2: '',
            address_line_3: '',
            post_town: 'Manchester',
            postcode: 'M1 1LS',
            postcode_spaceless: 'M11LS'
          },
          quotation: [
            {
              quotation_id: 1,
              journey_id: 100780,
              quotation_type: null,
              installer_id: 2,
              date_created: '2024-03-15T00:00:00.000Z',
              stage_type: 'pre_survey',
              gross_price: 14998,
              net_price: 7498,
              deposit_value: null,
              balance_value: null,
              manufacturer: 'Bosch',
              heatpump_size: '5',
              info_link: 'example.com',
              lead_id: 1,
              model: '2.5',
              goods: null,
              services: null,
              grants: [
                {
                  component_name: 'Boiler upgrade scheme',
                  component_value: -7500
                }
              ],
              estimate: [
                {
                  component_name: 'Equipment',
                  component_value: 12000
                },
                {
                  component_name: 'Installation',
                  component_value: 2000
                },
                {
                  component_name: 'Possible extras',
                  component_value: 998
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    lead_id: 2,
    resident_id: 100780,
    resident_first_name: 'Bob',
    resident_last_name: 'Johnson',
    property_address: '456 Elm Street',
    property_town: 'Manchester',
    property_postcode: 'M2 2CD',
    journey_id: 100780,
    lead_status: 0,
    property_coords: {
      lat: 51.282006,
      lon: -2.766804,
      region: 'South West'
    },
    journey: {
      journey_id: 100780,
      resident_id: 100780,
      property_id: '10',
      selected_installer_id: 2,
      selected_quotation_id: 1,
      post_survey_form_submission_id: null,
      post_installation_form_submission_id: null,
      journey_date_created: '2024-03-15T00:00:00.000Z',
      assessment_complete: 2,
      property_details_complete: 0,
      energy_consumption_complete: 0,
      shape_of_my_home_complete: 0,
      photos_of_my_home_complete: 2,
      price_estimates_requested: true,
      photos_accepted: false,
      survey_dates_requested: false,
      installers_provided: false,
      installer_selected: true,
      survey_dates_provided: false,
      survey_dates_selected: false,
      survey_booked: false,
      survey_complete: false,
      survey_documents_received: false,
      contract_signed: false,
      dno_notified: false,
      dno_confirmation: false,
      cool_down_start_date: null,
      cool_down_end_date: null,
      cool_down_completed: false,
      deposit_paid: false,
      installation_requested: false,
      equipment_ordered: false,
      installation_dates_provided: false,
      installation_dates_selected: false,
      installation_booked: false,
      installation_started: false,
      installation_completed: false,
      confirmation_documents_signed: false,
      outstanding_balance_paid: false,
      final_documentation_received: false,
      form_submissions: [
        {
          form_submission_id: '105060',
          form_description: 'heat_pump_readiness'
        },
        {
          form_submission_id: '105080',
          form_description: 'heat_pump_assumptions'
        },
        {
          form_submission_id: '105260',
          form_description: 'photos_of_my_home'
        }
      ],
      type: 'survey',
      thermly_heatloss_summary: {
        score_description: [
          {
            colour: '#92D050',
            description:
              'Having insulated walls is a big first step in being heat pump ready. All looks good at your place! Toasty.'
          },
          {
            colour: '#92D050',
            description:
              'An insulated roof. That should mean not much heat being lost up top which is ideal for heat pump installation.'
          },
          {
            colour: '#FFD966',
            description:
              "We'd recommend getting single glazing either replaced with double or triple glazing, or if that's not possible, to consider secondary glazing instead. More heat is lost through windows by area than anywhere else - so getting this sorted is important before progressing to installing a heat pump."
          }
        ],
        quote_breakdown: {
          heat_pump_size: '5',
          quote_breakdown: [
            {
              metrics_name: 'Equipment',
              metrics_value: '4700'
            },
            {
              metrics_name: 'Installation',
              metrics_value: '4000'
            },
            {
              metrics_name: 'Possible extras',
              metrics_value: '2600'
            },
            {
              metrics_name: 'Government grant',
              metrics_value: '-7500'
            },
            {
              metrics_name: 'Final cost',
              metrics_value: '3800'
            }
          ]
        }
      },
      resident_address: {
        property_id: '10',
        uprn: null,
        address_line_1: '2 kelsall place',
        address_line_2: '',
        address_line_3: '',
        post_town: 'Leeds',
        postcode: 'LS61RA',
        postcode_spaceless: 'LS61RA'
      },
      selected_installer: [
        {
          installer_id: 2,
          contact_name: 'Jhon Doe',
          contact_number: null,
          email: 'installer@yahoo.com',
          password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
          is_verified: true,
          social_signin: false,
          website_link: null,
          property_id: 11,
          date_created: '2024-03-14T00:00:00.000Z',
          installer_verification: true,
          logo: null,
          terms_and_conditions: true,
          company_name: 'Ecomatics',
          mcs_number: null,
          certification_body: null,
          company_bio: null,
          documents_form_submission_id: null,
          address: {
            property_id: '36',
            uprn: '77122047',
            address_line_1: '17 Piccadilly',
            address_line_2: '',
            address_line_3: '',
            post_town: 'Manchester',
            postcode: 'M1 1LS',
            postcode_spaceless: 'M11LS'
          },
          quotation: [
            {
              quotation_id: 1,
              journey_id: 100780,
              quotation_type: null,
              installer_id: 2,
              date_created: '2024-03-15T00:00:00.000Z',
              stage_type: 'pre_survey',
              gross_price: 14998,
              net_price: 7498,
              deposit_value: null,
              balance_value: null,
              manufacturer: 'Bosch',
              heatpump_size: '5',
              info_link: 'example.com',
              lead_id: 1,
              model: '2.5',
              goods: null,
              services: null,
              grants: [
                {
                  component_name: 'Boiler upgrade scheme',
                  component_value: -7500
                }
              ],
              estimate: [
                {
                  component_name: 'Equipment',
                  component_value: 12000
                },
                {
                  component_name: 'Installation',
                  component_value: 2000
                },
                {
                  component_name: 'Possible extras',
                  component_value: 998
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    lead_id: 1,
    resident_id: 100780,
    resident_first_name: 'David',
    resident_last_name: 'Lee',
    property_address: '456 Pine Street',
    property_town: 'Glasgow',
    property_postcode: 'G2 2FG',
    journey_id: 100780,
    lead_status: 2,
    property_coords: {
      lat: 51.282006,
      lon: -2.766804,
      region: 'South West'
    },
    journey: {
      journey_id: 100780,
      resident_id: 100780,
      property_id: '10',
      selected_installer_id: 2,
      selected_quotation_id: 1,
      post_survey_form_submission_id: null,
      post_installation_form_submission_id: null,
      journey_date_created: '2024-03-15T00:00:00.000Z',
      assessment_complete: 2,
      property_details_complete: 0,
      energy_consumption_complete: 0,
      shape_of_my_home_complete: 0,
      photos_of_my_home_complete: 2,
      price_estimates_requested: true,
      photos_accepted: false,
      survey_dates_requested: false,
      installers_provided: false,
      installer_selected: true,
      survey_dates_provided: false,
      survey_dates_selected: false,
      survey_booked: false,
      survey_complete: false,
      survey_documents_received: false,
      contract_signed: false,
      dno_notified: false,
      dno_confirmation: false,
      cool_down_start_date: null,
      cool_down_end_date: null,
      cool_down_completed: false,
      deposit_paid: false,
      installation_requested: false,
      equipment_ordered: false,
      installation_dates_provided: false,
      installation_dates_selected: false,
      installation_booked: false,
      installation_started: false,
      installation_completed: false,
      confirmation_documents_signed: false,
      outstanding_balance_paid: false,
      final_documentation_received: false,
      form_submissions: [
        {
          form_submission_id: '105060',
          form_description: 'heat_pump_readiness'
        },
        {
          form_submission_id: '105080',
          form_description: 'heat_pump_assumptions'
        },
        {
          form_submission_id: '105260',
          form_description: 'photos_of_my_home'
        }
      ],
      type: 'survey',
      thermly_heatloss_summary: {
        score_description: [
          {
            colour: '#92D050',
            description:
              'Having insulated walls is a big first step in being heat pump ready. All looks good at your place! Toasty.'
          },
          {
            colour: '#92D050',
            description:
              'An insulated roof. That should mean not much heat being lost up top which is ideal for heat pump installation.'
          },
          {
            colour: '#FFD966',
            description:
              "We'd recommend getting single glazing either replaced with double or triple glazing, or if that's not possible, to consider secondary glazing instead. More heat is lost through windows by area than anywhere else - so getting this sorted is important before progressing to installing a heat pump."
          }
        ],
        quote_breakdown: {
          heat_pump_size: '5',
          quote_breakdown: [
            {
              metrics_name: 'Equipment',
              metrics_value: '4700'
            },
            {
              metrics_name: 'Installation',
              metrics_value: '4000'
            },
            {
              metrics_name: 'Possible extras',
              metrics_value: '2600'
            },
            {
              metrics_name: 'Government grant',
              metrics_value: '-7500'
            },
            {
              metrics_name: 'Final cost',
              metrics_value: '3800'
            }
          ]
        }
      },
      resident_address: {
        property_id: '10',
        uprn: null,
        address_line_1: '2 kelsall place',
        address_line_2: '',
        address_line_3: '',
        post_town: 'Leeds',
        postcode: 'LS61RA',
        postcode_spaceless: 'LS61RA'
      },
      selected_installer: [
        {
          installer_id: 2,
          contact_name: 'Jhon Doe',
          contact_number: null,
          email: 'installer@yahoo.com',
          password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
          is_verified: true,
          social_signin: false,
          website_link: null,
          property_id: 11,
          date_created: '2024-03-14T00:00:00.000Z',
          installer_verification: true,
          logo: null,
          terms_and_conditions: true,
          company_name: 'Ecomatics',
          mcs_number: null,
          certification_body: null,
          company_bio: null,
          documents_form_submission_id: null,
          address: {
            property_id: '36',
            uprn: '77122047',
            address_line_1: '17 Piccadilly',
            address_line_2: '',
            address_line_3: '',
            post_town: 'Manchester',
            postcode: 'M1 1LS',
            postcode_spaceless: 'M11LS'
          },
          quotation: [
            {
              quotation_id: 1,
              journey_id: 100780,
              quotation_type: null,
              installer_id: 2,
              date_created: '2024-03-15T00:00:00.000Z',
              stage_type: 'pre_survey',
              gross_price: 14998,
              net_price: 7498,
              deposit_value: null,
              balance_value: null,
              manufacturer: 'Bosch',
              heatpump_size: '5',
              info_link: 'example.com',
              lead_id: 1,
              model: '2.5',
              goods: null,
              services: null,
              grants: [
                {
                  component_name: 'Boiler upgrade scheme',
                  component_value: -7500
                }
              ],
              estimate: [
                {
                  component_name: 'Equipment',
                  component_value: 12000
                },
                {
                  component_name: 'Installation',
                  component_value: 2000
                },
                {
                  component_name: 'Possible extras',
                  component_value: 998
                }
              ]
            }
          ]
        }
      ]
    }
  }
];

export const installerHomeStatsDemoData = {
  leads: {
    new_leads: '2',
    awaiting_response: '1',
    expired_leads: '0'
  },
  survey: {
    survey_slots_available: '20',
    survey_slots_booked: '0'
  },
  installations: {
    contract_signed: '0',
    deposit_paid: '0',
    installation_booked: '0',
    installation_completed: '0',
    outstanding_balance_paid: '0'
  }
};

export const installerDetailsDemo = {
  installer_id: 555,
  contact_name: 'John Doe',
  contact_number: '07440000000',
  email: 'Jon@test.com',
  password: '$2b$10$kA1cSSdoyN2kp54bNO32p.ZIjJAP2Pc30uPJbQkeIFibsb7/AZKD2',
  is_verified: true,
  social_signin: false,
  website_link: 'example.com',
  property_id: 36,
  date_created: '2024-03-14T00:00:00.000Z',
  installer_verification: true,
  logo: null,
  terms_and_conditions: true,
  company_name: 'Ecomatics',
  mcs_number: 'CAE324156',
  certification_body: null,
  company_bio:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel bibendum massa. Nam porttitor nulla egestas ante mollis lacinia. Sed sollicitudin mattis fringilla. Integer nec odio vehicula, faucibus sapien non, condimentum ligula. Aliquam dignissim eu nunc vitae tincidunt. Morbi sed dolor et massa commodo tincidunt.',
  documents_form_submission_id: null
};

export const CalenderSlotsDemo = (numSlots) => {
  function randomDate(start, end) {
    const startHour = 8; // Start hour (8am)
    const endHour = 21; // End hour (9pm)
    const startMilliseconds = start.setHours(startHour, 0, 0, 0); // Set start hour
    const endMilliseconds = end.setHours(endHour, 0, 0, 0); // Set end hour
    let startDate = new Date(
      startMilliseconds + Math.random() * (endMilliseconds - startMilliseconds)
    );
    let endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
    return [startDate, endDate];
  }

  let today = new Date();

  let startDate = new Date(today);
  startDate.setDate(startDate.getDate() - 15);

  let endDate = new Date(today);
  endDate.setDate(endDate.getDate() + 15);

  let slots = [];
  for (let i = 0; i < numSlots; i++) {
    let [startTime, endTime] = randomDate(startDate, endDate);
    slots.push({
      id: Math.floor(Math.random() * 100000000000).toString(), // Generating random id
      installer_id: Math.floor(Math.random() * 10) + 1, // Random installer ID from 1 to 10
      booking_status: 0,
      journey_id: null,
      starttime: startTime.toISOString(),
      endtime: endTime.toISOString(),
      subject: 'Survey Slot',
      location: null,
      description: null,
      isallday: false, // Assuming time slots are not all day
      slottype: 'survey',
      starttimezone: null,
      endtimezone: null,
      recurrencerule: null,
      recurrenceid: null,
      recurrenceexception: null,
      followingid: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }
  for (let i = 0; i < numSlots; i++) {
    let [startTime, endTime] = randomDate(startDate, endDate);
    slots.push({
      id: Math.floor(Math.random() * 100000000000).toString(), // Generating random id
      installer_id: Math.floor(Math.random() * 10) + 1, // Random installer ID from 1 to 10
      booking_status: 0,
      journey_id: null,
      starttime: startTime.toISOString(),
      endtime: endTime.toISOString(),
      subject: 'Installation Slot',
      location: null,
      description: null,
      isallday: false, // Assuming time slots are not all day
      slottype: 'installation',
      starttimezone: null,
      endtimezone: null,
      recurrencerule: null,
      recurrenceid: null,
      recurrenceexception: null,
      followingid: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }

  return slots;
};
