import React from 'react';
import './LoadingWithBackdrop.style.css';

const LoadingWithBackdropComponent = () => (
  <div className="loading">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

export default LoadingWithBackdropComponent;
