import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import './MonthDataInput.styles.css';

const MonthDataInputComponent = (props) => {
  const { helperText, error, onMonthDataChange } = props;
  const [monthData, setMonthData] = useState({});
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();

  // return past 12 months from the current month
  const months = Array.from({ length: 12 }, (_, index) => {
    const monthIndex = (currentMonthIndex - index - 1 + 12) % 12;
    const year = currentYear - (currentMonthIndex - index - 1 < 0 ? 1 : 0);
    const month = new Date(year, monthIndex).toLocaleString('default', { month: 'long' });
    const monthLabel = `${month} ${year}`;
    const monthKey = `${month.toLowerCase()}_${year}`; // Format the key as "month_year"
    return { monthIndex, year, monthLabel, monthKey };
  });

  const handleInputChange = (monthKey, value) => {
    const updatedData = {
      ...monthData,
      [monthKey]: value
    };
    setMonthData(updatedData);
    onMonthDataChange(updatedData);
  };

  const handleCancel = (monthKey) => {
    const { [monthKey]: _, ...updatedData } = monthData;
    setMonthData(updatedData);
    onMonthDataChange(updatedData);
  };

  return (
    <div className="monthInputContainer">
      {months.map(({ monthIndex, year, monthLabel, monthKey }) => {
        const inputValue = monthData[monthKey] || '';
        const isMonthDataPresent = !!monthData[monthKey];

        return (
          <div key={`${monthIndex}-${year}`} className="inputField">
            <label className="text-small black-font">{monthLabel}</label>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(monthKey, e.target.value)}
              className="text-small black-font"
              style={{
                border: error && '1px solid var(--orange-color)'
              }}
            />
            {isMonthDataPresent && (
              <CancelIcon
                className="cancelIcon"
                sx={{
                  color: error ? 'var(--orange-color)' : 'var(--emerald-color)',
                  cursor: 'pointer'
                }}
                onClick={() => handleCancel(monthKey)}
              />
            )}
          </div>
        );
      })}
      {error && <label className="text-small orange-font">{helperText}</label>}
    </div>
  );
};
export default MonthDataInputComponent;
