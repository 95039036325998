import {
  SET_RESIDENT_ID,
  SET_JOURNEY_ID,
  SET_RESIDENT_DETAILS,
  SET_JOURNEY_DETAILS,
  SET_DETAIL_DATA_SUBMISSION_DETAIL,
  SET_INSTALLER_DETAILS,
  SET_LEADS_DETAILS,
  SET_HOME_STATS,
  SET_LOADING,
  UPDATE_LEAD,
  UPDATE_INSTALLER_DETAILS,
  LOGOUT,
  TOGGLE_DEMO_MODE,
  SET_NOTIFICATIONS,
  SET_ORDERS_DETAILS,
  UPDATE_ORDER
} from '../Types/user.type';

export const toggleDemoMode = () => {
  return {
    type: TOGGLE_DEMO_MODE
  };
};

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    payload: value
  };
};

export const updateLead = (leadId, key, value) => {
  return {
    type: UPDATE_LEAD,
    payload: { leadId, key, value }
  };
};

export const updateOrder = (orderId, orderKey, orderValue) => {
  return {
    type: UPDATE_ORDER,
    payload: { orderId, orderKey, orderValue }
  };
};

export const setResidentID = (value) => {
  // Return an object representing the action
  return {
    type: SET_RESIDENT_ID,
    payload: value
  };
};

export const setInstallerDetails = (value) => {
  return {
    type: SET_INSTALLER_DETAILS,
    payload: value
  };
};

export const updateInstallerDetails = (value) => {
  return {
    type: UPDATE_INSTALLER_DETAILS,
    payload: value
  };
};

export const setLeadsDetails = (value) => {
  return {
    type: SET_LEADS_DETAILS,
    payload: value
  };
};

export const setOrdersDetails = (value) => {
  return {
    type: SET_ORDERS_DETAILS,
    payload: value
  };
};
export const setJourneyID = (value) => {
  // Return an object representing the action
  return {
    type: SET_JOURNEY_ID,
    payload: value
  };
};

export const setResidentDetails = (value) => {
  // Return an object representing the action
  return {
    type: SET_RESIDENT_DETAILS,
    payload: value
  };
};

export const setJourneyDetails = (value) => {
  // Return an object representing the action
  return {
    type: SET_JOURNEY_DETAILS,
    payload: value
  };
};
export const setDetailDataSubmissionId = (value) => ({
  type: SET_DETAIL_DATA_SUBMISSION_DETAIL,
  payload: value
});

export const setHomeStats = (value) => ({
  type: SET_HOME_STATS,
  payload: value
});

export const setNotifications = (value) => ({
  type: SET_NOTIFICATIONS,
  payload: value
});

export const logoutt = () => ({
  type: LOGOUT
});
