import React, { useState } from 'react';
import LoginForm from '../../components/Auth/LoginForm/LoginForm';
import RegisterForm from '../../components/Auth/RegisterForm/RegisterForm';
import Overlay from '../../components/Auth/Overlay/Overlay';
import SocialButtonGroup from '../../components/Auth/SocialButtonGroup';
import Logo from '../../images/thermly-icon.png';

import './AuthPage.styles.css';
import { Alert, Snackbar } from '@mui/material';
import InstallerInfoForm from '../../components/Auth/InstallerInfoForm/InstallerInfoForm.component';

export default function InstallerAuthPage({ userType }) {
  const [signIn, setSignIn] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const toggleFormHandle = () => {
    setSignIn(!signIn);
  };
  const handleOpen = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSnackClose = (event, reason) => {
    setOpen(false);
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={'success'} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="containe-wide">
        <div className="auth-container container-wide">
          <div className="text-align-center auth-text">
            <img src={Logo} alt={'logo'} height="75px" color="var(--orange-color)" loading="lazy" />
            <Overlay
              greeting={userType === 'installer' ? 'Welcome Installers!' : 'Welcome to Thermly!'}
              msg={signIn ? "We're glad you're here" : "Let's start your journey with us"}
              signIn={signIn}
            />
          </div>
          <div className="auth-form">
            <InstallerInfoForm
              userType={userType}
              toggleFormHandle={toggleFormHandle}
              handleOpen={handleOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
}
