import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { customMuiTheme } from './styles/muiTheme';
import AppRoutes from './routes';
import { AuthProvider } from './auth/AuthProvider';
import './App.css';
import './styles/globalLayout.css';
import ReactGA from 'react-ga4';

export default function App() {
  useEffect(() => {
    // Initialise Google Analytics tracking with react-ga4
    if (
      process.env.REACT_APP_ENV_NAME === 'production' ||
      process.env.REACT_APP_ENV_NAME === 'staging'
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
    }
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Landing Page' });
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={customMuiTheme}>
        <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
