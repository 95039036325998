import {
  SET_FINAL_ADDRESS,
  SET_HP_ASSESMENT_Form,
  GET_Form_DATA_SUCCESS,
  GET_Form_DATA_PENDING,
  GET_Form_DATA_ERROR,
  SET_AWS_FETCH,
  SET_CURRENT_QUESTION_INDEX,
  SET_AREA_OPERATION
} from '../Types/forms.type';

const initialState = {
  formData: [],
  formId: '',
  loading: false,
  error: false,
  errorMessage: '',
  addressForm: {},
  hpAssesmentForm: {},
  currentQuestionIndex: 0,
  awsFetchComplete: false,
  areaOperation: true
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINAL_ADDRESS:
      return {
        ...state,
        addressForm: action.payload
      };
    case SET_HP_ASSESMENT_Form:
      return {
        ...state,
        hpAssesmentForm: action.payload
      };

    case GET_Form_DATA_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false
      };

    case GET_Form_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case GET_Form_DATA_SUCCESS:
      return {
        ...state,
        formData: action.formData,
        loading: false,
        formId: action.formId,
        error: false,
        errorMessage: ''
      };
    case SET_AWS_FETCH:
      return {
        ...state,
        awsFetchComplete: action.payload
      };
    case SET_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestionIndex: action.payload
      };
    case SET_AREA_OPERATION:
      return {
        ...state,
        areaOperation: action.payload
      };

    default:
      return state;
  }
};

export default formReducer;
