// This module exports a function named CallApi that makes an HTTP request using axios library and handles any errors that may occur during the request
import axios from 'axios';

// Define an asynchronous function CallApi that accepts options as a parameter

export const CallApi = async (options) => {
  axios.defaults.withCredentials = true;
  const instance = axios.create({
    withCredentials: true
  });
  try {
    // Make an HTTP request using axios with the provided options
    const response = await instance(options);
    const { data } = response;
    // Return an object containing the data and no error message
    return {
      data,
      error: null
    };
  } catch (error) {
    // If an axios error occurs, handle it by extracting error messages from the response and return an error object
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      const { response } = axiosError;
      let message = 'http request failed';

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      // Return an object containing no data and the error message
      return {
        data: null,
        error: {
          message
        }
      };
    }

    // If a non-axios error occurs, return an object containing no data and the error message
    return {
      data: null,
      error: {
        message: error.message
      }
    };
  }
};
