import {
  SET_RESIDENT_ID,
  SET_JOURNEY_ID,
  SET_RESIDENT_DETAILS,
  SET_JOURNEY_DETAILS,
  SET_DETAIL_DATA_SUBMISSION_DETAIL,
  SET_INSTALLER_DETAILS,
  SET_LEADS_DETAILS,
  SET_HOME_STATS,
  SET_LOADING,
  UPDATE_LEAD,
  UPDATE_INSTALLER_DETAILS,
  LOGOUT,
  TOGGLE_DEMO_MODE,
  SET_NOTIFICATIONS,
  SET_ORDERS_DETAILS,
  UPDATE_ORDER
} from '../Types/user.type';

const initialState = {
  resident_id: Number,
  journey_id: Number,
  resident_details: {},
  installer_details: {},
  journey_details: {},
  leads: [],
  orders: [],
  data_capture_details: [],
  home_stats: {},
  loading: false,
  demo: false,
  notifications: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case TOGGLE_DEMO_MODE:
      return {
        demo: !state.demo
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        installer_notifications: action.payload
      };
    case SET_RESIDENT_ID:
      return {
        ...state,
        resident_id: action.payload
      };
    case SET_JOURNEY_ID:
      return {
        ...state,
        journey_id: action.payload
      };
    case SET_RESIDENT_DETAILS:
      return {
        ...state,
        resident_details: action.payload
      };
    case SET_INSTALLER_DETAILS:
      return {
        ...state,
        installer_details: action.payload
      };
    case UPDATE_INSTALLER_DETAILS:
      return {
        ...state,
        installer_details: {
          ...state.installer_details,
          ...action.payload
        }
      };

    case SET_JOURNEY_DETAILS:
      return {
        ...state,
        journey_details: action.payload
      };
    case SET_LEADS_DETAILS:
      return {
        ...state,
        leads: action.payload
      };
    case SET_ORDERS_DETAILS:
      return {
        ...state,
        orders: action.payload
      };
    case UPDATE_LEAD: {
      const { leadId, key, value } = action.payload;
      const updatedLeads = state.leads.map((lead) => {
        if (lead.lead_id === leadId) {
          return { ...lead, [key]: value };
        }
        return lead;
      });
      return {
        ...state,
        leads: updatedLeads
      };
    }

    case UPDATE_ORDER: {
      const { orderId, orderKey, orderValue } = action.payload;
      const updatedOrders = state.orders.map((order) => {
        if (order.order_id === orderId) {
          return { ...order, [orderKey]: orderValue };
        }
        return order;
      });
      return {
        ...state,
        orders: updatedOrders
      };
    }

    case SET_DETAIL_DATA_SUBMISSION_DETAIL:
      return {
        ...state,
        data_capture_details: action.payload
      };
    case SET_HOME_STATS:
      return {
        ...state,
        homeStats: action.payload
      };
    case LOGOUT:
      return (state = initialState);
    default:
      return state;
  }
};

export default userReducer;
