//Types for HP report
export const GET_HPREPORT_DATA_SUCCESS = 'GET_HPREPORT_DATA_SUCCESS';

export const GET_HPREPORT_DATA_PENDING = 'GET_HPREPORT_DATA_PENDING';

export const GET_HPREPORT_DATA_ERROR = 'GET_HPREPORT_DATA_ERROR';

export const SET_WAITING_LIST_API_ERROR = 'SET_WAITING_LIST_API_ERROR';

export const GET_HPQUESTIONS_SUCCESS = 'GET_HPQUESTIONS_SUCCESS';
export const GET_HPQUESTIONS_ERROR = 'GET_HPQUESTIONS_ERROR';
export const GET_HPQUESTIONS_PENDING = 'GET_HPQUESTIONS_PENDING';
