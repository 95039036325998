import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { SentimentNeutral } from '@mui/icons-material';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
    color: 'var(--red-color)'
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
    color: 'var(--orange-color)'
  },
  3: {
    icon: <SentimentNeutral />,
    label: 'Neutral',
    color: 'var(--yellow-color)'
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
    color: 'var(--green-color-half)'
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
    color: 'var(--green-color)'
  }
};

export default function CustomInputRating({
  question,
  onChange,
  onBlur,
  error,
  helperText,
  formikProps,
  inputStyles,
  precision,
  readOnly,
  ...rest
}) {
  const { options } = question;
  const [selectedIcon, setSelectedIcon] = useState(question.value);

  const handleChange = (newValue) => {
    setSelectedIcon(newValue);
    if (onChange) {
      onChange({
        target: {
          name: question.id,
          value: newValue
        }
      });
    }
  };

  const renderRatingIcons = () => {
    return (
      <Stack spacing={1}>
        <Rating
          name="custom-icon-rating"
          value={selectedIcon}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          max={5}
          IconContainerComponent={(props) => {
            const { value } = props;
            return (
              <Tooltip title={customIcons[value].label} arrow>
                {React.cloneElement(customIcons[value].icon, {
                  style: {
                    color: selectedIcon === value ? customIcons[value].color : 'var(--bg5)',
                    fontSize: selectedIcon === value ? '52px' : '38px',
                    transition: 'color 0.3s ease, font-size 0.3s ease'
                  }
                })}
              </Tooltip>
            );
          }}
          {...rest}
        />
      </Stack>
    );
  };

  return (
    <div className="ratingInput">
      {renderRatingIcons()}
      <label className="text-small orange-font"> {helperText} </label>
    </div>
  );
}
