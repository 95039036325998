import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import './HelperThermly.style.css';

function HelperThermly({ thermly_text, inputStyles, thermly_image }) {
  const mediaQuery = window.matchMedia('(max-width: 780px)');

  // Calculate the minWidth based on the media query result
  const minWidth = mediaQuery.matches ? '250px' : '600px';
  const flexClass = mediaQuery.matches ? 'flex-cols-nowrap' : 'flex-row';
  const localServer = process.env.REACT_APP_SERVER_URL;
  const styles = inputStyles ? inputStyles : 'var(--orange-color)';
  return (
    <Tooltip
      title={
        <div className="flex-card">
          <div className="title-medium emerald-font">Thermly help</div>
          <div className={`${flexClass} 'align-item-center justify-center align-center gap-2'`}>
            <p className="text-standard grey-font">{thermly_text}</p>
            {thermly_image && (
              <img
                src={`${localServer}/images/thermlies/${thermly_image}.svg`}
                alt="Thermly helper"
                className="red-svg"
                height="75px"
                style={{ marginLeft: '20px' }}
              />
            )}
          </div>
        </div>
      }
      arrow
      interactive="true"
      enterTouchDelay={0}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px 0px rgba(34, 34, 34, 0.06)',
            border: '2px solid rgba(242, 242, 240, 1)',
            opacity: '0.1',
            minWidth: minWidth,
            padding: '25px',
            paddingLeft: '50px',
            marginLeft: '10px'
          }
        }
      }}>
      <InfoIcon className="info-icon" sx={{ color: 'var(--orange-color)' }} />
    </Tooltip>
  );
}

export default HelperThermly;
