import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Custom components
import { AddressFinder } from '@ideal-postcodes/address-finder';
import CustomForm from '../../../components/Common/Forms/form.component';
import LoadingWithBackdropComponent from '../../../components/Common/Loader/LoadingWithBackdrop.component';
import Alert from '../../../components/Common/Alerts/Alert.component';
import {
  getFormdataError,
  getFormdataPending,
  getFormdataSuccess
} from '../../../redux/Actions/forms.action';

//services and styles
import { getSingle, post } from '../../../services/API';
import '../../../pages/ReadinessAssessment/AddressLookup/addressLookup.styles.css';

import CustomTextField from '../../../components/Common/Forms/Inputs/TextField/TextField.component';
import axios from 'axios';

export default function CustomAddressFinder({
  installerJourneyUpdate,
  setResidentAddress,
  residentAddress,
  setShowAddressLookup,
  getJourney,
  installer
}) {
  const dispatch = useDispatch();
  const [formData, setformData] = useState([]);
  const loading = useSelector((state) => state.formReducer.loading);
  const error = useSelector((state) => state.formReducer.error);
  const message = useSelector((state) => state.formReducer.errorMessage);
  const [isManualEntry, setisManualEntry] = useState(false);
  const [addressSearchError, setAddressSearchError] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [addressFinderShowForm, setAddressFinderShowForm] = useState(false);
  const [postCodeError, setPostCodeError] = useState(false);
  const journey_details = useSelector((state) => state.userReducer.journey_details);
  // State variables for the address lookup fields
  const [address, setAddress] = useState({
    address_lookup_line_1: '',
    address_lookup_line_2: '',
    address_lookup_line_3: '',
    address_lookup_post_town: '',
    address_lookup_postcode: '',

    address_lookup_uprn: null
  });

  const arePostcodesEqual = (postcode1, postcode2) => {
    const formatPostcode = (postcode) => postcode && postcode.replace(/\s+/g, '').toLowerCase();
    return formatPostcode(postcode1) === formatPostcode(postcode2);
  };

  // Fetch addressLookup form data
  const getaddressForm = async () => {
    dispatch(getFormdataPending());
    const url = 'form/request';
    const res = await getSingle(url, 'address_lookup');
    if (res.data) {
      // Set form data in the redux
      const formData = res.data.sort((a, b) => a.question_number - b.question_number);
      formData.pop();
      const formDataWithPostCode = formData.map((field) => {
        if (field.id === 'address_lookup_postcode' && journey_details.installer_selected) {
          // Set the postcode and disable the input field
          return {
            ...field,
            value: residentAddress?.postcode, // Replace with the actual postcode value
            disabled: true
          };
        }
        return field;
      });

      setformData(formDataWithPostCode);
      dispatch(getFormdataSuccess(formData, 'address_lookup'));
    }
    if (res.error) {
      // set error in the redux
      dispatch(getFormdataError({ error: true, errorMessage: res.error.message }));
    }
  };

  //submit handler
  const handleSubmit = async (values, { setSubmitting }) => {
    let finalAddress = {
      ...values,
      address_lookup_uprn: address.address_lookup_uprn
    };

    const obj = {
      uprn: finalAddress.address_lookup_uprn,
      address_line_1: finalAddress.address_lookup_line_1,
      address_line_2: finalAddress.address_lookup_line_2,
      address_line_3: finalAddress.address_lookup_line_3,
      post_town: finalAddress.address_lookup_post_town,
      postcode: finalAddress.address_lookup_postcode,
      postcode_spaceless: finalAddress.address_lookup_postcode.replace(/\s/g, '')
    };
    const obj1 = {
      property_id: journey_details.property_id
    };

    let result;
    // api call
    if (installer) {
      result = await post('users', 'createProperty', [
        finalAddress.address_lookup_uprn,
        finalAddress.address_lookup_line_1,
        finalAddress.address_lookup_line_2,
        finalAddress.address_lookup_line_3,
        finalAddress.address_lookup_post_town,
        finalAddress.address_lookup_postcode,
        finalAddress.address_lookup_postcode.replace(/\s/g, '')
      ]);
    } else {
      result = await post('users', 'updateProperty', { dataObjects: obj, property: obj1 });
    }

    if (result) {
      setShowAddressLookup && setShowAddressLookup(false);
      getJourney && getJourney();
      finalAddress = {
        address: { ...finalAddress },
        submission_detail: { property_id: result.data.property_id }
      };
      // dispatches a Redux action to set the address lookup values
      setSubmitting(false);
      installerJourneyUpdate && installerJourneyUpdate(result.data);
      installer && setResidentAddress(finalAddress.address);
      getProperty(installer ? result.data : journey_details.property_id);
    } else {
      console.log('Some issue with updating your property. Try again.');
    }
  };

  const getProperty = async (property_id) => {
    const res = await getSingle('users', `getProperty/${property_id}`);
    if (res.data) {
      setResidentAddress(res.data);
    }
  };

  // Setting up address finder and fetching the address
  const fetchAddress = () => {
    axios.defaults.withCredentials = false;
    AddressFinder.setup({
      containerStyle: {
        fontFamily: 'Sofia Pro Soft'
      },
      mainStyle: {
        boxShadow: 'none'
      },

      inputField: '#addressInput',
      outputFields: {
        line_1: '#address_lookup_line_1',
        line_2: '#address_lookup_line_2',
        line_3: '#address_lookup_line_3',
        post_town: '#address_lookup_post_town',
        postcode: '#address_lookup_postcode'
      },
      // test api key
      apiKey: window.apiKey || 'ak_test',
      autocomplete: 'off',
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, postcode, uprn }) => {
        // document.querySelector(".idpc-unhide").style.display = "none";
        const addressForms = document.querySelectorAll('.addressForm');
        addressForms.forEach((el) => {
          el.classList.toggle('hidden');
        });
        setisManualEntry(true);
        setAddressFinderShowForm(true);
        setAddress({
          address_lookup_line_1: line_1,
          address_lookup_line_2: line_2,
          address_lookup_line_3: line_3,
          address_lookup_post_town: post_town,
          address_lookup_postcode: postcode,
          address_lookup_uprn: null
        });
        // if (
        //   !journey_details.installer_selected ||
        //   (postcode === specificPostcode && journey_details.installer_selected)
        // ) {
        //   setPostCodeError(false);
        // } else {
        //   setPostCodeError(
        //     `Address selected is not in the ${journey_details?.resident_adddress.postcode}. If you are not able to find the address try manually entry below.`
        //   );
        // }
      },
      onFailedCheck: () => {
        setAddressSearchError(true);
      },
      onSearchError: () => {
        setAddressSearchError(true);
      },
      onSuggestionError: () => {
        setAddressSearchError(true);
      }
    });
  };

  const toggleFormVisibility = () => {
    setisManualEntry(!isManualEntry);
    if (addressFinderShowForm) {
      setAddressFinderShowForm(false);
    }
    setFormKey((prevKey) => prevKey + 1); // Update formKey to force re-render
  };

  useEffect(() => {
    console.log('ismanu', isManualEntry);
    if (formData.length === 0) {
      getaddressForm();
    } else {
      if (!addressSearchError) {
        fetchAddress();
      }
    }
  }, [formData, isManualEntry]);

  // useEffect(() => {
  //   if (
  //     address.address_lookup_postcode === residentAddress.postcode &&
  //     journey_details.installer_selected
  //   ) {
  //     setPostCodeError(true);
  //   }

  //   // eslint-disable-next-line
  // }, [address.address_lookup_postcode]);

  return (
    <div>
      {loading ? (
        <div className="loaderClass">
          <LoadingWithBackdropComponent />
        </div>
      ) : error ? (
        <Alert type="error" message={message} />
      ) : (
        <div className="addressFormContainer">
          <p
            className={`text-small emerald-font ${isManualEntry === true || addressSearchError ? 'hidden' : ''}`}>
            Use the address finder below to find your home.
          </p>
          <div className={`addressForm ${isManualEntry || addressSearchError ? 'hidden' : ''}`}>
            <label className="text-large emerald-font">Search Address</label>
            <CustomTextField
              id="addressInput"
              placeholder="Find your address"
              resetValue={isManualEntry}
              inputStyles="var(--emerald-color)"
            />
            {addressSearchError && (
              <p className="orange-font text-standard mb-4">
                {`Sorry! There is some issue with the address finder. Please enter your address manually. `}
              </p>
            )}
          </div>
          {!isManualEntry && (
            <a className="text-small emerald-font address_type" onClick={toggleFormVisibility}>
              Enter address manually
            </a>
          )}
          <div id="hiddenfields" className={`addressForm ${isManualEntry ? '' : 'hidden'}`}>
            {!addressSearchError && (
              <a className="text-small emerald-font address_type" onClick={toggleFormVisibility}>
                Search Again
              </a>
            )}
            {/* Rest of the fields */}
            <CustomForm
              formData={formData}
              small={true}
              noLines={true}
              button_position_css="flex-row-wrap justify-end"
              handleSubmit={handleSubmit}
              formKey={formKey}
              labelStyles="text-standard emerald-font"
              inputStyles="var(--emerald-color)"
              buttonLabel={'Save'}
              buttonExtraClasses={
                !installer &&
                !arePostcodesEqual(residentAddress?.postcode, address.address_lookup_postcode) &&
                addressFinderShowForm &&
                journey_details.installer_selected
                  ? 'd-none'
                  : ''
              }
            />
            {!installer &&
              !arePostcodesEqual(residentAddress?.postcode, address.address_lookup_postcode) &&
              addressFinderShowForm &&
              journey_details.installer_selected && (
                <p className="orange-font text-standard">
                  {' '}
                  {`Enter an address with postcode matching that previously entered - ${residentAddress?.postcode}`}
                </p>
              )}
          </div>
        </div>
      )}
    </div>
  );
}
