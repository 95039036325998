//temp image
import OptionsImage from '../../../../../images/demo_options.svg';
import CustomCard from '../../../Card/card.component';
import './CardWrapper.style.css';

export default function CardWrapper(props) {
  const { questionsData, handleSubmit } = props;
  let type = questionsData.image ? 'mini' : 'standard';
  // let type =  "standard";

  const localServer = process.env.REACT_APP_SERVER_URL;
  const sortedOptions = questionsData.options.sort((a, b) => a.option_id - b.option_id);

  return (
    <>
      <div className={'card-wrapper' + (type === 'mini' ? ' mini-card-wrapper' : '')}>
        <div className={type === 'standard' ? 'standard-container' : 'mini-container'}>
          {sortedOptions.map((value, index) => (
            <CustomCard
              index={index}
              key={index + '_' + value.option_value}
              value={value.option_value}
              handleOnClick={handleSubmit}
              questionsData={questionsData}
              image={value.option_image}
              title={value.option_label}
              description={value.option_description}
              type={type}
            />
          ))}
        </div>
        {questionsData.image && (
          <div className="mini-card-image-container">
            <img src={`${localServer}/images/${questionsData.image}.svg`} className="image-style" />
          </div>
        )}
      </div>
    </>
  );
}
