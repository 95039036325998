import {
  GET_HPREPORT_DATA_SUCCESS,
  GET_HPREPORT_DATA_PENDING,
  GET_HPREPORT_DATA_ERROR,
  SET_WAITING_LIST_API_ERROR,
  GET_HPQUESTIONS_SUCCESS,
  GET_HPQUESTIONS_ERROR,
  GET_HPQUESTIONS_PENDING
} from '../Types/hpAssessment.type';

const initialState = {
  hpQuestions: {},
  hpReportData: {},
  loading: false,
  error: false,
  errorMessage: '',
  waitingListError: false,
  waitingListErrorMessage: ''
};

const hpaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HPQUESTIONS_SUCCESS:
      return {
        ...state,
        hpQuestions: action.payload,
        loading: false,
        error: false,
        errorMessage: ''
      };
    case GET_HPQUESTIONS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false
      };
    case GET_HPQUESTIONS_PENDING:
      return {
        ...state,
        loading: true
      };

    case GET_HPREPORT_DATA_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false
      };

    case GET_HPREPORT_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case GET_HPREPORT_DATA_SUCCESS:
      return {
        ...state,
        hpReportData: action.hpReportData,
        loading: false,
        error: false,
        errorMessage: ''
      };
    case SET_WAITING_LIST_API_ERROR:
      return {
        ...state,
        waitingListError: action.payload.error,
        waitingListErrorMessage: action.payload.errorMessage
      };

    default:
      return state;
  }
};

export default hpaReducer;
