import { useState } from 'react';
import './MultiInputCard.style.css';

export default function MultiInputCard(props) {
  const { question, onChange, onBlur, error, helperText, formikProps, inputStyles, ...rest } =
    props;

  const [selectedOption, setSelectedOption] = useState(formikProps.values[question.id]);
  const localServer = process.env.REACT_APP_SERVER_URL;
  const handleOnChange = (value) => {
    setSelectedOption(value);
    if (onChange) {
      formikProps.setFieldValue(question.id, value);
      onChange(value);
    }
  };
  return (
    <div className="radioGroup">
      {/* radio buttons group */}
      <div className="radio-card-container">
        {question.options &&
          question.options.map((option) => (
            <div
              key={`radio-card${option.option_value}`}
              className={'radio-card'}
              style={{
                backgroundColor:
                  selectedOption === option.option_value ? inputStyles : 'var(--earth-color)'
              }}
              onClick={() => handleOnChange(option.option_value)}>
              {option.option_image ? (
                <img
                  key={`img${option.id}`}
                  src={`${localServer}/images/${option.option_image}.svg`}
                  // Set the desired width and height
                  alt={`Descriptive illustration for ${option.option_label}`}
                  className="radio-card-option-image"></img>
              ) : null}
              <label
                key={option.option_value}
                className={`${selectedOption === option.option_value ? 'white-font' : 'grey-font'} text-standard align-self`}>
                {option.option_label}
              </label>
            </div>
          ))}
      </div>

      {/* errors */}
      <label className="text-small orange-font"> {helperText} </label>
    </div>
  );
}
