import { useEffect, useState } from 'react';
import './NativeRadioGroup.style.css';

export default function NativeRadioGroup(props) {
  const { question, onChange, onBlur, error, helperText, formikProps, inputStyles, ...rest } =
    props;

  const [selectedOption, setSelectedOption] = useState(formikProps.values[question.id]);
  const handleOnChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div className="nativeRadioGroup flex-card gap-half grey-font">
      {/* radio buttons group */}
      {question.options &&
        question.options.map((option) => (
          <label
            name={question.id}
            key={`radio${option.option_value} `}
            className="onhover-pointer">
            <input
              type="radio"
              className="nativeRadioInput "
              id={option.id}
              name={question.id}
              onBlur={onBlur}
              value={option.option_value}
              checked={selectedOption === option.option_value}
              onChange={handleOnChange}
              style={{
                backgroundColor:
                  selectedOption === option.option_value ? inputStyles : 'var(--earth-color)' // Use the prop for checked state
              }}
              {...rest}
            />
            {/* radio label */}
            <span
              key={`label${option.option_value}`}
              className={`${selectedOption === option.option_value ? 'emerald-font' : 'grey-font'} text-standard nativeRadioLabel-text`}>
              {option.option_label}
            </span>
          </label>
        ))}
      {/* errors */}
      <label className="text-small orange-font"> {helperText} </label>
    </div>
  );
}
