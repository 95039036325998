import { useState } from 'react';
import '../Checkbox.style.css';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PdfViewer from '../../../../../PdfViewer/PdfViewer';
import CustomButton from '../../../../Button/Button.component';
export default function CheckboxWithDialog(props) {
  const { question, onChange, onBlur, error, helperText, inputStyles, labelStyles, ...rest } =
    props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleAccept = () => {
    setIsAccepted(true);
    setIsDialogOpen(false);
    setChecked(true);
    if (onChange) {
      onChange({ target: { name: question.id, value: true } });
    }
  };

  const handleOnChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (onChange) {
      onChange(event);
    }
  };

  const fileLinks = {
    terms: {
      href: '/Thermly%20Platform%20-%20User%20Terms.pdf',
      download: 'Thermly Platform - User Terms.pdf'
    },
    privacy_policy: {
      href: '/Thermly%20Platform%20-%20Privacy%20Policy.pdf',
      download: 'Thermly Platform - Privacy Policy.pdf'
    }
  };

  const handleDownload = (filename) => {
    // Implement the download logic here
    const link = document.createElement('a');
    if (fileLinks[filename]) {
      link.href = fileLinks[filename].href;
      link.download = fileLinks[filename].download;
    }

    link.click();
    setIsDownloaded(true);
  };

  return (
    <div className="checkbox">
      <div className="checkbox-container">
        <input
          type="checkbox"
          className="checkbox-input"
          id={question.id}
          onBlur={onBlur}
          value={checked}
          checked={checked}
          onChange={handleOnChange}
          {...rest}
        />
        <label
          className="checkbox-custom"
          style={{
            border:
              question.label_short === 'registration'
                ? '1px solid var(--emerald-color)'
                : '1px solid #fff '
            // boxSizing: 'border-box'
          }}
          htmlFor={question.id}></label>
        {/* checkbox label */}
        <div className={`text-small checkbox-label ${labelStyles}`}>
          {/* {!isAccepted ? question.label : 'I accept the '} */}
          {question.label}{' '}
          <a
            onClick={() => handleDownload('terms')}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Terms &amp; Conditions
          </a>{' '}
          and acknowledge that I have read and understand the{' '}
          <a
            onClick={() => handleDownload('privacy_policy')}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Privacy Policy
          </a>
          .
        </div>

        {/* Dialog */}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="lg">
          <DialogTitle id="alert-dialog-title">{'Read full terms and conditions'}</DialogTitle>
          <DialogContent>
            <PdfViewer
              pdfUrl="/Thermly%20Platform%20-%20User%20Terms.pdf"
              setIsScrolled={setIsScrolled}
              isScrolled={isScrolled}
            />
          </DialogContent>
          <DialogActions className="dialog-action-buttons">
            {/* Download button */}
            <CustomButton
              label={'Cancel'}
              small
              cssType={'primary'}
              onClick={() => setIsDialogOpen(false)}
            />
            <CustomButton label={'Download'} small cssType={'secondary'} onClick={handleDownload} />
            <CustomButton
              label={'Accept'}
              small
              cssType={'secondary'}
              disabled={isScrolled || isDownloaded ? false : true}
              onClick={handleAccept}
            />
          </DialogActions>
        </Dialog>
      </div>
      {/* errors */}
      <p className="text-small orange-font"> {helperText} </p>
    </div>
  );
}
